import type { UUID } from "utils";

const BASE_URL = "/connect";
const ACCOUNTS_BASE_URL = `${BASE_URL}/accounts`;
const CONFIGURATION_BASE_URL = `${BASE_URL}/configuration`;
const PROVIDER_CONFIGS_BASE_URL = `${BASE_URL}/providers`;
const SETTLEMENTS_BASE_URL = "/transactions/settlements";
const CUSTOMER_BASE_URL = "/configuration/customers";

export const URL_PARAMS = {
  CONFIGURE: "configure",
};

export const CONFIGURE_PARAM_VALUES = {
  PROVIDERS: {
    CLEAR_JUNCTION: "clearJunctionProvider",
  },
  NOTIFICATIONS: {
    PAYOUT: "payoutNotifications",
    REPORT: "reportNotifications",
    CREDIT: "creditNotifications",
  },
};

export const PAGE_URLS = {
  dashboard: "/",
  core: BASE_URL,
  settlements: SETTLEMENTS_BASE_URL,
  settlementDetails: `${SETTLEMENTS_BASE_URL}/:id`,
  accounts: `ACCOUNTS_BASE_URL`,
  providers: PROVIDER_CONFIGS_BASE_URL,
  isxFinancialConfiguration: `${BASE_URL}/isx-financial-configuration`,
  configuration: CONFIGURATION_BASE_URL,
  accountDetails: `${ACCOUNTS_BASE_URL}/:accountId`,
  accountCreate: `${ACCOUNTS_BASE_URL}/create`,
  payoutNotificationsConfiguration: `${CONFIGURATION_BASE_URL}?${URL_PARAMS.CONFIGURE}=${CONFIGURE_PARAM_VALUES.NOTIFICATIONS.PAYOUT}`,
  reportNotificationsConfiguration: `${CONFIGURATION_BASE_URL}?${URL_PARAMS.CONFIGURE}=${CONFIGURE_PARAM_VALUES.NOTIFICATIONS.REPORT}`,
  creditNotificationsConfiguration: `${CONFIGURATION_BASE_URL}?${URL_PARAMS.CONFIGURE}=${CONFIGURE_PARAM_VALUES.NOTIFICATIONS.CREDIT}`,
  clearJunctionProviderConfiguration: `${PROVIDER_CONFIGS_BASE_URL}?${URL_PARAMS.CONFIGURE}=${CONFIGURE_PARAM_VALUES.PROVIDERS.CLEAR_JUNCTION}`,
  getLinkToAccountDetails: (accountId: UUID) =>
    `${ACCOUNTS_BASE_URL}/${accountId}`,
  getSettlementDetails: (id: UUID) => `${SETTLEMENTS_BASE_URL}/${id}`,
  getLinkToCustomerDetails: (id: UUID) => `${CUSTOMER_BASE_URL}/${id}`,
};
