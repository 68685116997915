import { Transactions as TransactionsIcon } from "@volt_developers/react-ui-components";

import { ACTION_DETAILS, ACTION_LIST } from "models/role/action";
import { SUBJECT_PAYMENT } from "models/role/subject";
import { lazyRetryWithPreload } from "utils/lazyRetryWithPreload";

import { PAGE_URLS } from "../constants/PageUrls";
import { payByLinkRoutes } from "./payByLink";

import type { Route } from "routes/RouteModel";

const PaymentList = lazyRetryWithPreload(
  () => import("../pages/List"),
  "PaymentList"
);

const PaymentPage = lazyRetryWithPreload(
  () => import("../pages/payment"),
  "PaymentPage"
);

export const transactionsRoutes: Route = {
  i18nName: "Transactions",
  path: PAGE_URLS.list,
  newTheme: true,
  icon: TransactionsIcon,
  component: PaymentList,
  showChildrenInSidebar: false,
  can: {
    do: ACTION_LIST,
    on: SUBJECT_PAYMENT,
  },
  children: [
    ...payByLinkRoutes,
    {
      path: PAGE_URLS.details,
      i18nName: "Transaction",
      newTheme: true,
      component: PaymentPage,
      can: {
        do: ACTION_DETAILS,
        on: SUBJECT_PAYMENT,
      },
      hasNestedSwitch: true,
    },
  ],
};
